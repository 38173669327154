import { EntityType, FolderDetail, RecipeInputType, RecipeStep, SDWorkflow, Tag } from "@/api/sdk"
import ClientOnly from "@/components/ClientOnly"
import LoadingLogo from "@/components/LoadingLogo"
import NotFoundUI from "@/components/NotFound"
import { PhoneOff2Icon } from "@/components/shared/icons"
import RequestModal from "@/components/Workspace/ImageDetailWorkspace/RequestModal"
import { CheckableRecipeInput } from "@/components/Workspace/Recipes/RecipeDetail"
import { useDebounce, useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { googleAnalytics } from "@/lib/gtag"
import Postmate from "@/lib/postmate"
import { useTrackingViewMutation } from "@/queries"
import { useGetOngoingTaskQuery } from "@/queries/task/useGetTaskInfiniteQuery"
import { useAddTaskMutation, useTagsSDWorkflowsMutation } from "@/queries/tools/comfyui-recipe"
import { useComfyUiWorkflowDetailQuery } from "@/queries/tools/comfyui-recipe/getComfyUiRecipeQueries"
import {
  usePublishComfyUiWorkflowMutation,
  useUpdateComfyUiWorkflowMutation,
} from "@/queries/tools/comfyui-recipe/updateComfyUiRecipeMutations"
import { useComfyUiNodeInfosQuery } from "@/queries/tools/comfyui-recipe/useComfyUiNodeInfosQuery"
import { useAddTagRecipesMutation, useGetRecipeDetailQuery } from "@/queries/workspace/recipe"
import { useQueryClient } from "@tanstack/react-query"
import { AnimatePresence, motion } from "framer-motion"
import Router from "next/router"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { isMobileOnly } from "react-device-detect"
import { useForm } from "react-hook-form"
import { useBeforeUnload } from "react-use"
import { ComfyUIGraph, ComfyUINodeInfos } from "../../../ComfyUI/types/node"
import { GraphField, extractGraphInputs } from "../../../ComfyUI/utils/graph"
import { TurboModeProvder, useTurboMode } from "../../../Workspace/TurboMode/useTurboMode"
import ComfyUIActions from "./ComfyUIActions"
import { uploadComfyUIWorkflowFiles } from "./ComfyUIRecipeInfo"
import ComfyUIWorkflowTopbar from "./ComfyUITopbar"
import { ComfyUIWorkflowSettingsRef } from "./ComfyUIWorkflowSettings"
import { ComfyUIWorkflowStepsRef } from "./ComfyUIWorkflowSteps"
import LeftBarOption from "./LeftbarOption"
import PublishRecipeModal, { PublishRecipeFormData } from "./PublishRecipeModal"

export type ComfyUIProps = {
  workflowId: string
  nodeInfos?: ComfyUINodeInfos
  isLoadingInfoNode?: boolean
  isReadOnly?: boolean
  isModal?: boolean
}

type AutoSaveSDWorkflowDto = Partial<SDWorkflow & { thumbnailFile: File; tags: Tag[] }>

Postmate.debug = process.env.NEXT_PUBLIC_ENV !== "production"

export type ModeComfy =
  | "info"
  | "convert-recipe"
  | "comment"
  | "tasks-history"
  | "task-tracker"
  | "publish-recipe"
  | "prompt-library"

export type SettingTab = "add-inputs" | "preview" | "published"

export const COMFY_RECIPE_ID = "comfyui"

const isGraphChanged = (prev?: ComfyUIGraph, curr?: ComfyUIGraph) => {
  if (!prev && !curr) return false
  if (!prev || !curr) return true

  const _prev = {
    nodes: prev.nodes,
    links: prev.links,
    groups: prev.groups,
    last_node_id: prev.last_node_id,
    last_link_id: prev.last_link_id,
  }

  const _curr = {
    nodes: curr.nodes,
    links: curr.links,
    groups: curr.groups,
    last_node_id: curr.last_node_id,
    last_link_id: curr.last_link_id,
  }

  return JSON.stringify(_prev) !== JSON.stringify(_curr)
}

const ComfyUI: FC<ComfyUIProps> = ({ workflowId, nodeInfos, isReadOnly, isModal, isLoadingInfoNode }) => {
  const router = useCustomRouter()
  const toast = useToast()
  const qc = useQueryClient()

  const [isEditing, setEditting] = useState(false)
  const [isLeftbarOpen, setLeftbarOpen] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isCompletedTask, setIsCompletedTask] = useState(false)
  const [isTaskOnGoing, setIsTaskOnGoing] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)

  const postmateInitiated = useRef(false)
  const frameContainer = useRef<HTMLDivElement>(null)
  const [postmate, setPostmate] = useState<Postmate.ParentAPI>()
  const postmateRef = useRef(postmate)

  const [name, _setName] = useState<string | null>(null)
  const [description, _setDescription] = useState<string>()
  const [tags, _setTags] = useState<Tag[]>([])
  const [file, _setFile] = useState<File | null>(null)
  const [graphInputs, setGraphInputs] = useState<GraphField[]>([])
  const [steps, setSteps] = useState<RecipeStep[]>([])
  const settingsRef = useRef<ComfyUIWorkflowSettingsRef>(null)
  const recipeFormRef = useRef<ComfyUIWorkflowStepsRef>(null)
  const [folder, setFolder] = useState<FolderDetail>()
  const [canvasClick, setCanvasClick] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [mode, setMode] = useState<ModeComfy | null>(null)
  const [tab, setTab] = useState<SettingTab>("add-inputs")
  const settingsForm = useForm<RecipeStep>({ reValidateMode: "onBlur" })

  const {
    data: workflow,
    isSuccess,
    isLoading,
    error,
    isError,
    refetch: refetchWorkflowDetail,
  } = useComfyUiWorkflowDetailQuery({
    variables: { workflowId },
    enabled: !!workflowId && !!nodeInfos,
  })

  const isOpenRequest = useDebounce(!isLoading && !!workflow && !workflow.capabilities?.canView, 500)
  const statusErrorCode = Number((error as any)?.statusCode)

  const { data: lastRecipe } = useGetRecipeDetailQuery({
    variables: { recipeId: workflow?.lastRecipeId ?? "" },
    enabled: !!workflow,
  })

  const publishFormData = useMemo<PublishRecipeFormData>(
    () => ({
      override: false,
      name: lastRecipe?.name || workflow?.name || "My Recipe",
      description: lastRecipe?.description || workflow?.description || "",
      thumbnailUrl: lastRecipe?.thumbnail || workflow?.thumbnailUrl || "",
      thumbnailId: lastRecipe?.thumbnail || workflow?.thumbnailFileId || "",
      tags: lastRecipe?.tags || [],
      category: lastRecipe?.categories?.[0]?.id.toString() || "",
    }),
    [workflow, lastRecipe],
  )

  const { isReady: isTurboSessionReady, queueTask: queueTurboTask } = useTurboMode()

  const { mutateAsync: updateWorkflow } = useUpdateComfyUiWorkflowMutation({
    onSuccess(data) {
      if (data.isDraft) return

      toast({
        status: "success",
        title: "Success!",
        message: ["ComfyUi workflow is updated successfully!"],
        duration: 5000,
      })
    },
  })

  const { mutateAsync: autoUpdateWorkflow, isPending: isUpdatingWorkflow } = useUpdateComfyUiWorkflowMutation()

  const { mutateAsync: createTask, isPending: isCreatingTask } = useAddTaskMutation({
    onSuccess: ({ id, params }, variables) => {
      const recipeOngoingKey = useGetOngoingTaskQuery.getKey({
        recipeIds: [COMFY_RECIPE_ID],
        sdWorkflowId: variables.sdWorkflowId,
      })
      qc.invalidateQueries({ queryKey: recipeOngoingKey })

      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Submit Generated Task Completed",
          comfyui_id: variables.sdWorkflowId,
          comfyui_name: workflow?.name || "",
          task_id: id,
        },
      })

      toast({
        status: "success",
        title: "Submitted! Your task is running",
        duration: 5000,
      })
    },
    onError: error => {
      setIsGenerating(false)
      toast({
        status: "error",
        title: "Error",
        message: [error.message],
      })
    },
  })

  const { mutateAsync: publishRecipe } = usePublishComfyUiWorkflowMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Recipe published!",
        message: [`Recipe "${data.name}" is published successfully.`],
      })
    },
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message],
      })
    },
  })

  const { mutate: addTags } = useAddTagRecipesMutation()

  const handleSelectComfyUINode = useCallback(
    (comfyKey: string) => {
      if (!comfyKey || !postmate) return

      const [id, _, widget] = comfyKey.split(".")

      return postmate.call("selectWidget", { id, widget })
    },
    [postmate],
  )

  const [hasPendingSave, setPendingSave] = useState(false)
  const autoSaveData = useRef<AutoSaveSDWorkflowDto>({})
  const autoSaveTimer = useRef<NodeJS.Timeout>()

  const handleAutoSave = async () => {
    if (!postmateRef.current) return

    const { id, name, description, thumbnailFile, tags } = autoSaveData.current
    if (!id) return

    const { workflow: graph, output: prompt } = await postmateRef.current.get("validatePrompt")

    const newSteps = await settingsRef.current?.validateSettings(false)

    let thumbnailFileId: string | undefined = undefined
    if (thumbnailFile) {
      setIsUploading(true)
      const [uploaded] = await uploadComfyUIWorkflowFiles([thumbnailFile])

      if (uploaded) {
        thumbnailFileId = uploaded.id
        autoSaveData.current.thumbnailFile = undefined
      } else {
        toast({
          status: "error",
          title: "Error",
          message: ["Failed to upload thumbnail image."],
        })
        setIsUploading(false)
        return
      }

      setFile(null)
      setIsUploading(false)
    }

    await autoUpdateWorkflow({
      workflowId: id,
      data: {
        name,
        description,
        thumbnailFileId,
        workflow: graph,
        steps: newSteps,
        prompt,
      },
    }).then(async data => {
      if (tags) {
        await mutateTags({
          sdWorkflowIds: [data.id],
          tagIds: tags.map(tag => tag.id),
        })
      }
      setPendingSave(false)
      refetchWorkflowDetail()
    })
  }

  const scheduleAutoSave = (data: AutoSaveSDWorkflowDto, timeout = 300000) => {
    Object.assign(autoSaveData.current, data)
    clearTimeout(autoSaveTimer.current)
    setPendingSave(true)
    autoSaveTimer.current = setTimeout(() => {
      console.log("auto save workflow", data)
      handleAutoSave()
    }, timeout)
  }

  const setName = (name: string | null) => {
    _setName(prev => {
      if (name && prev !== name) {
        scheduleAutoSave({ name })
      }

      return name
    })
  }

  const setDescription = (desc?: string) => {
    _setDescription(prev => {
      if (prev !== desc) {
        scheduleAutoSave({ description: desc })
      }

      return desc
    })
  }

  const setTags = (tags: Tag[] | ((prev: Tag[]) => Tag[])) => {
    _setTags(prev => {
      const newTags = typeof tags === "function" ? tags(prev) : tags
      if (JSON.stringify(prev) !== JSON.stringify(newTags)) {
        scheduleAutoSave({ tags: newTags })
        return newTags
      }

      return prev
    })
  }

  const setFile = (file: File | null) => {
    _setFile(file)
    if (file) {
      scheduleAutoSave({ thumbnailFile: file })
    }
  }

  const handleGraphChanged = (graph: ComfyUIGraph) => {
    if (!nodeInfos) return

    const inputs = extractGraphInputs(graph, nodeInfos)
    setGraphInputs(prev => {
      const changed = JSON.stringify(prev) !== JSON.stringify(inputs)
      return changed ? inputs : prev
    })
    if (isGraphChanged(graph, autoSaveData.current.workflow as any)) {
      scheduleAutoSave({ workflow: graph })
    }
  }

  const handleSwitchTab = async (value: SettingTab) => {
    if (tab === value) return

    if (value === "preview") {
      const steps = await settingsRef.current?.validateSettings()
      if (!steps) {
        const errorClassName = document.getElementsByClassName(" error-message")

        if (errorClassName.length > 0) {
          const errorElement = errorClassName[0] as HTMLElement
          errorElement.scrollIntoView({ behavior: "smooth" })
        }

        return
      }

      setSteps(steps)
    } else {
      // settingsRef.current?.setSettings(steps ?? [])
    }

    setTab(value)
  }

  const handleStartEditing = () => {
    setEditting(true)
    setLeftbarOpen(true)
    if (!mode) {
      setMode("info")
    }
    postmate?.call("setReadOnly", false)
  }

  const [preview, setPreview] = useState<string | null>(null)

  const { mutateAsync: mutateTags } = useTagsSDWorkflowsMutation()

  const handleSave = async () => {
    clearTimeout(autoSaveTimer.current)
    if (!workflow || !postmate) return

    // const steps = await settingsRef.current?.validateSettings()
    // if (!steps) return

    const { workflow: graph, output: prompt } = await postmate.get("validatePrompt")
    const newSteps = await settingsRef.current?.validateSettings()

    if (!newSteps) {
      handleSwitchTab("add-inputs")

      toast({
        status: "error",
        title: "Error",
        message: ["Please add at least one input."],
      })
      return
    }

    // if (!preview && !file) {
    //   setLeftbarOpen(true)
    //   if (!mode || mode !== "info") {
    //     setMode("info")
    //   }

    //   toast({
    //     status: "error",
    //     title: "Error",
    //     message: ["Please upload a thumbnail image."],
    //   })

    //   return
    // }

    let thumbnailFileId = workflow.thumbnailFileId

    if (file) {
      setIsUploading(true)
      const [uploaded] = await uploadComfyUIWorkflowFiles([file])

      if (uploaded) {
        thumbnailFileId = uploaded.id
      } else {
        toast({
          status: "error",
          title: "Error",
          message: ["Failed to upload thumbnail image."],
        })
        setIsUploading(false)
        return
      }

      setFile(null)
      setIsUploading(false)
    }

    await updateWorkflow({
      workflowId: workflow.id,
      data: {
        name: name || workflow.name || "New comfyUI",
        description,
        thumbnailFileId,
        workflow: graph,
        steps: newSteps,
        isDraft: false,
        prompt,
      },
    }).then(async data => {
      await mutateTags({
        sdWorkflowIds: [data.id],
        tagIds: tags.map(tag => tag.id),
      })
      Object.assign(autoSaveData.current, data)
      setPendingSave(false)
      refetchWorkflowDetail()
    })
  }

  const handleDownloadGraph = async () => {
    if (!workflow || !postmate) return

    const { workflow: graph } = await postmate.get("validatePrompt")

    const jsonGraph = JSON.stringify(graph, null, 2)

    const blob = new Blob([jsonGraph], { type: "text/plain" })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = `${name ?? "comfy-ui"}.json`
    a.click()
  }

  const handleGenerate = useCallback(async () => {
    if (!postmate) return

    const { workflow, output: prompt } = await postmate.get("validatePrompt")

    let t: any

    if (isTurboSessionReady) {
      t = await queueTurboTask({
        params: { prompt, workflow },
        recipeId: COMFY_RECIPE_ID,
        sdWorkflowId: workflowId,
      })
      const recipeOngoingKey = useGetOngoingTaskQuery.getKey({
        recipeIds: [COMFY_RECIPE_ID],
        sdWorkflowId: workflowId,
      })
      qc.invalidateQueries({ queryKey: recipeOngoingKey })

      toast({
        status: "success",
        title: "Submitted! Your task is running",
        duration: 5000,
      })
    } else {
      setIsGenerating(true)

      t = await createTask({
        params: { prompt, workflow },
        recipeId: COMFY_RECIPE_ID,
        sdWorkflowId: workflowId,
      })
    }

    setIsGenerating(false)
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Submit Generated Task",
        comfyui_id: workflowId,
        comfyui_name: workflow?.name,
      },
    })

    postmate.call("onPrompt", { id: t.id, workflow })
  }, [postmate, isTurboSessionReady, workflowId, queueTurboTask, createTask])

  const handleCancelConfig = () => {
    setMode(null)
    setLeftbarOpen(false)
    // reset steps
    if (workflow) {
      setSteps(workflow.steps)
      settingsRef.current?.setSettings(workflow.steps)
    }
  }

  const recipeInputsRef = useRef<CheckableRecipeInput[]>([])
  const handleRecipeInputChange = useCallback((inputs: CheckableRecipeInput[]) => {
    recipeInputsRef.current = inputs
  }, [])

  const handleOpenPublishModal = async () => {
    const steps = await settingsRef.current?.validateSettings(true)
    if (!steps) return

    setModalOpen(true)
  }

  const handlePublishRecipe = async (values: PublishRecipeFormData) => {
    if (!workflow || !postmate) return

    const steps = await settingsRef.current?.validateSettings()
    if (!steps) return

    // set default recipe value
    steps.forEach(step => {
      step.inputs.forEach(input => {
        const value = recipeInputsRef.current.find(i => i.key === input.key)
        if (value) {
          input.value = value.value
        }
      })
    })

    const { tags: recipeTags, category, ...other } = values

    return publishRecipe({
      workflowId: workflow.id,
      data: {
        ...other,
        categoryIds: [parseInt(category)],
        steps,
      },
    }).then(data => {
      refetchWorkflowDetail()
      addTags({
        recipeIds: [data.id],
        tagIds: recipeTags.map(tag => tag.id),
      })
      // setMode(null)
      // setLeftbarOpen(false)
      setModalOpen(false)
    })
  }

  const handleDropFile = (file: File) => {
    if (isReadOnly || !isEditing || !postmate) return

    //read file to json
    const reader = new FileReader()
    reader.onload = async () => {
      try {
        const graph = JSON.parse(reader.result as string)

        postmate.call("loadGraph", graph)
        handleGraphChanged(graph)
      } catch (error) {
        toast({
          status: "error",
          title: "Error",
          message: ["Invalid JSON file."],
        })
      }
    }

    reader.readAsText(file)
  }

  const [selectedNodes, setSelectedNodes] = useState<string[]>([])
  const [scale, setScale] = useState(1)

  const { watch } = settingsForm
  const lastSettingsRef = useRef<any>()
  useEffect(() => {
    const subscription = watch(value => {
      if (JSON.stringify(lastSettingsRef.current) !== JSON.stringify(value)) {
        lastSettingsRef.current = value
        scheduleAutoSave({})
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (postmate) {
      postmate.on("graphChanged", handleGraphChanged)

      postmate.on("addInput", (props: { id: string; type: RecipeInputType }) => {
        setTab("add-inputs")
        settingsRef.current?.addInput(props.type, props.id)
      })

      postmate.on("onSelectionChange", (nodeIds: string[]) => {
        setSelectedNodes(nodeIds)
      })

      postmate.on("canvasClick", event => {
        if (event === "click") {
          setCanvasClick(true)
        }
      })

      postmate.on("onScaleChange", (scale: number) => {
        setScale(scale)
      })

      postmate.on("onDropFile", (file: File) => {
        handleDropFile(file)
      })
    }
  }, [postmate])

  useEffect(() => {
    if (!postmate) return

    const recipeOngoingKey = useGetOngoingTaskQuery.getKey({
      recipeIds: [COMFY_RECIPE_ID],
      sdWorkflowId: workflowId,
    })

    const onTaskFailed = () => {
      setTimeout(() => {
        qc.invalidateQueries({ queryKey: recipeOngoingKey })
      }, 1000)
    }

    postmate.on("onValidationFailed", onTaskFailed)
    postmate.on("onExecutionFailed", onTaskFailed)

    return () => {
      postmate.off("onValidationFailed", onTaskFailed)
      postmate.off("onExecutionFailed", onTaskFailed)
    }
  }, [postmate, workflowId])

  useEffect(() => {
    if (workflow) {
      const steps = workflow.steps ?? []
      const inputs = steps.flatMap(s => s.inputs)
      lastSettingsRef.current = { inputs }
      settingsRef.current?.setSettings(steps)

      setSteps(steps)
      setPreview(workflow.thumbnailUrl)
      _setTags(workflow.tags ?? [])
      _setName(workflow.name)
      _setDescription(workflow.description)
      Object.assign(autoSaveData.current, workflow)
    }
  }, [workflow])

  useEffect(() => {
    if (workflow && postmate) {
      postmate.call("loadGraph", workflow.workflow)
      handleGraphChanged(workflow.workflow as any)
    }
  }, [workflow, postmate])

  const { mutate: mutateTrackingView } = useTrackingViewMutation()

  useEffect(() => {
    if (!router.isReady) return

    if (isSuccess && postmate) {
      mutateTrackingView({
        entityId: workflowId,
        entityType: EntityType.SD_WORKFLOW,
      })
      googleAnalytics.event({
        action: "view",
        category: "comfyui_detail",
        label: "view_workspace_comfyui_detail",
        params: {
          comfyui_id: workflowId,
          comfyui_name: workflow?.name,
          pathname: window.location.pathname,
        },
      })

      if (isModal && isReadOnly) {
        postmate.call("setIsModal", true)
        postmate.call("setReadOnly", true)
        return
      }

      if (workflow.capabilities?.canUpdate) {
        setEditting(true)
        postmate.call("setReadOnly", false)
      } else {
        setEditting(false)
        postmate.call("setReadOnly", true)
      }
    }
  }, [isSuccess, postmate, isReadOnly, isModal, router.isReady])

  useEffect(() => {
    if (router.isReady && router.query.notificationId) {
      setMode("comment")
      setLeftbarOpen(true)
    }
  }, [router.isReady])

  useEffect(() => {
    if (!postmate) return
    postmate.call("isCreatingTask", isCreatingTask)
  }, [postmate, isCreatingTask])

  useEffect(() => {
    if (postmateInitiated.current || isMobileOnly) return
    postmateInitiated.current = true

    const pm = new Postmate({
      container: frameContainer.current,
      url: "/comfyui/frame?embed=true",
      name: "comfyui",
      classListArray: ["absolute", "top-0", "left-0", "w-full", "h-full", "border-none", "outline-none", "m-0", "p-0"],
      maxHandshakeRequests: Infinity,
    })
    pm.then(postmate => {
      postmateRef.current = postmate
      setPostmate(postmate)
    })
  }, [])

  useBeforeUnload(isEditing && hasPendingSave, "Leave site? Changes you made may not be saved.")

  useEffect(() => {
    const handler = () => {
      if (isEditing && hasPendingSave && !window.confirm("Leave page? Changes you made may not be saved.")) {
        throw "Route Canceled"
      }
    }

    Router.events.on("beforeHistoryChange", handler)
    return () => {
      Router.events.off("beforeHistoryChange", handler)
    }
  }, [isEditing, hasPendingSave])

  const [isSettingsOpen, setSettingsOpen] = useState(false)
  const [isLibraryOpen, setLibraryOpen] = useState(false)

  const handleToggleSettings = () => {
    if (isLibraryOpen) {
      setLibraryOpen(false)
      postmate?.call("toggleNodeLibrary")
    }

    setLeftbarOpen(false)
    setMode(null)
    postmate?.call("toggleSettings")
    setSettingsOpen(curr => !curr)
  }

  const handleToggleNodeLibrary = () => {
    if (isSettingsOpen) {
      setSettingsOpen(false)
      postmate?.call("toggleSettings")
    }

    setLeftbarOpen(false)
    setMode(null)
    postmate?.call("toggleNodeLibrary")
    setLibraryOpen(curr => !curr)
  }

  const handleToggleAction = (changeMode?: ModeComfy) => {
    if (isSettingsOpen) {
      setSettingsOpen(false)
      postmate?.call("toggleSettings")
    }

    if (isLibraryOpen) {
      setLibraryOpen(false)
      postmate?.call("toggleNodeLibrary")
    }

    if (changeMode && mode !== changeMode) {
      setMode(changeMode)
      setLeftbarOpen(true)
    } else {
      setMode(null)
      setLeftbarOpen(false)
    }
  }

  const handleConfigRecipe = async () => {
    if (isSettingsOpen) {
      setSettingsOpen(false)
      postmate?.call("toggleSettings")
    }

    if (isLibraryOpen) {
      setLibraryOpen(false)
      postmate?.call("toggleNodeLibrary")
    }

    if (mode !== "convert-recipe") {
      setMode("convert-recipe")
      setLeftbarOpen(true)
      handleSwitchTab("add-inputs")
    } else {
      handleCancelConfig()
    }
  }

  const renderBodyComfyUI = () => {
    if (isLoading || isLoadingInfoNode) {
      return (
        <div className="fixed z-[1] top-0 left-0 h-full bg-atherGray-950 w-full flex items-center justify-center">
          <LoadingLogo />
        </div>
      )
    }

    if (isError || !workflow || !nodeInfos) {
      if (statusErrorCode === 403)
        return (
          <RequestModal
            onRefetch={refetchWorkflowDetail}
            dataRequest={{
              entityId: workflowId,
              entityType: EntityType.SD_WORKFLOW,
            }}
            isOpenRequest={true}
            onCloseRequestModal={() => router.push("/workspace/tools/comfyui")}
          />
        )

      return (
        <div className="z-[1] fixed top-0 left-0 h-full bg-atherGray-900 w-full flex flex-col items-center justify-center">
          <NotFoundUI description="ComfyUI" redirectTo={"/workspace/tools/comfyui"} />
        </div>
      )
    }
  }

  return (
    <>
      {!isModal && !isReadOnly && (
        <ComfyUIWorkflowTopbar
          isLoading={isLoading}
          isGenerating={isGenerating}
          postmate={postmate}
          scale={scale}
          workflow={workflow}
          name={name}
          isCompletedTask={isCompletedTask}
          canvasClick={canvasClick}
          setCanvasClick={setCanvasClick}
          handleGenerate={handleGenerate}
        />
      )}
      <div className="flex flex-1 flex-col w-full relative h-[calc((var(--vh,1vh)*100-3rem))] overflow-hidden text-atherGray-100">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex flex-1 w-full items-stretch overflow-hidden"
        >
          <div className="hidden md:flex flex-col border-r border-atherGray-800 bg-atherGray-900 w-14">
            <ComfyUIActions
              workflow={workflow}
              mode={mode}
              onSave={handleSave}
              hasPendingSave={hasPendingSave}
              isSettingsOpen={isSettingsOpen}
              isLibraryOpen={isLibraryOpen}
              isTaskOnGoing={isTaskOnGoing}
              onToggleTasks={() => handleToggleAction("tasks-history")}
              onToggleTaskTracker={() => handleToggleAction("task-tracker")}
              onTogglePublish={() => handleToggleAction("publish-recipe")}
              onTogglePromptLibrary={() => handleToggleAction("prompt-library")}
              onToggleSettings={handleToggleSettings}
              onToggleNodeLibrary={handleToggleNodeLibrary}
              onToggleConvertRecipe={handleConfigRecipe}
              onTogglePreview={() => handleToggleAction("info")}
              onToggleComments={() => handleToggleAction("comment")}
              onDownloadGraph={handleDownloadGraph}
              isAutoSaving={isUpdatingWorkflow}
            />
            <LeftBarOption
              isLeftbarOpen={isLeftbarOpen}
              handleToggleAction={() => handleToggleAction()}
              mode={mode}
              tab={tab}
              selectedNodes={selectedNodes}
              handleSwitchTab={handleSwitchTab}
              handleSelectComfyUINode={handleSelectComfyUINode}
              workflow={workflow}
              isEditing={isEditing}
              canvasClick={canvasClick}
              setCanvasClick={setCanvasClick}
              isUploading={isUploading}
              preview={preview}
              setPreview={setPreview}
              file={file}
              setFile={setFile}
              postmate={postmate}
              tags={tags}
              setTags={setTags}
              name={name}
              description={description}
              setDescription={setDescription}
              setIsTaskOnGoing={setIsTaskOnGoing}
              setIsCompletedTask={setIsCompletedTask}
              handleGenerate={handleGenerate}
              isCreatingTask={isCreatingTask}
              folder={folder}
              setFolder={setFolder}
              workflowId={workflowId}
              setName={setName}
              settingsRef={settingsRef}
              settingsForm={settingsForm}
              handleOpenPublishModal={handleOpenPublishModal}
              graphInputs={graphInputs}
              steps={steps}
              handleConfigRecipe={handleConfigRecipe}
              handleRecipeInputChange={handleRecipeInputChange}
              recipeFormRef={recipeFormRef}
            />
          </div>

          {isMobileOnly ? (
            <AnimatePresence>
              {!isLeftbarOpen && (
                <div className="relative flex-1 flex flex-col items-center px-2 bg-black select-none justify-center w-full text-gray-600">
                  <PhoneOff2Icon className="text-atherGray-300" />
                  <h2 className="text-2xl my-2 font-semibold text-atherGray-0">Oops!!</h2>
                  <p className={"text-base text-atherGray-300 text-center"}>
                    This feature is not available on Mobile devices.
                    <br />
                    To continue, please try accessing via PC again. Thank you!
                  </p>
                </div>
              )}
            </AnimatePresence>
          ) : (
            <div className="relative flex-1 flex flex-col items-center justify-between" ref={frameContainer}></div>
          )}

          {renderBodyComfyUI()}
        </motion.div>
      </div>

      <PublishRecipeModal
        workflow={workflow}
        lastRecipe={lastRecipe}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        defaultValue={publishFormData}
        onPublish={handlePublishRecipe}
      />

      {workflow && (
        <RequestModal
          onRefetch={refetchWorkflowDetail}
          dataRequest={{
            entityId: workflow.id,
            entityType: EntityType.SD_WORKFLOW,
            workspace: workflow.workspace,
          }}
          isOpenRequest={isOpenRequest}
          onCloseRequestModal={() => router.push("/workspace/tools/comfyui")}
        />
      )}
    </>
  )
}

export type ComfyUIRecipeBuilderProps = {
  workflowId: string
  className?: string
  isReadOnly?: boolean
  isModal?: boolean
}

const ComfyUIRecipeBuilder: FC<ComfyUIRecipeBuilderProps> = ({ workflowId, className, isReadOnly, isModal }) => {
  const { data: nodeInfos, isLoading } = useComfyUiNodeInfosQuery()

  return (
    <TurboModeProvder>
      <ClientOnly>
        <ComfyUI
          isLoadingInfoNode={isLoading}
          workflowId={workflowId}
          nodeInfos={nodeInfos}
          isReadOnly={isReadOnly}
          isModal={isModal}
        />
      </ClientOnly>
    </TurboModeProvder>
  )
}

export { ComfyUIRecipeBuilder }
