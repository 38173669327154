import { css } from "@emotion/css"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"
import MenuItem from "./MenuItem"
import VersionApp from "./VersionApp"
import WorkspaceSelector from "./WorkspaceSelector"

import { isProduction } from "@/components/Layouts/LazyProviders"
import {
  BackLogIcon,
  BookMarkIcon2,
  ClockIcon,
  ColorPickerIcon,
  DeleteIcon,
  FlowIcon,
  FlowSquareIcon,
  FolderOpenIcon,
  HomeIcon,
  ModelIcon,
  MyFoldersIcon,
  PinCodeIcon,
  PinIcon,
  ScienceIcon,
  UserMultiIcon,
} from "@/components/shared/icons"
import { LibraryIcon } from "@/components/shared/icons/LibraryIcon"
import MenuItemParent from "./MenuItemParent"
import ProgressbarStorage from "./ProgressbarStorage"
import ShortcutKeyboard from "./ShortcutKeyboard"

interface SidebarBodyProps {
  className?: string
  onSelectedMenu?: (path: string) => void
  hiddenWorkspaceSelector?: boolean
}

const SidebarBody = ({ className, hiddenWorkspaceSelector, onSelectedMenu }: SidebarBodyProps) => {
  return (
    <div
      className={twMerge(classNames("h-full flex flex-col relative w-80 pt-4 overflow-auto px-6 z-[20]"), className)}
    >
      {!hiddenWorkspaceSelector && (
        <>
          <WorkspaceSelector />
          <hr className="my-4 border-atherGray-800" />
        </>
      )}
      <div className="flex flex-col flex-1 overflow-hidden">
        <div
          className={css("", {
            "::-webkit-scrollbar": {
              display: "none",
            },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          })}
        >
          <div className="mb-2 space-y-2">
            <MenuItem
              title="Home Space"
              icon={<HomeIcon width={24} height={24} />}
              path="/workspace"
              onSelected={onSelectedMenu}
            />

            <MenuItem
              title="Macros"
              icon={<FlowIcon width={24} height={24} />}
              path="/workspace/macros"
              onSelected={onSelectedMenu}
            />
            <MenuItemParent
              values={[
                "/workspace/tools/recipes",
                "/workspace/tools/styles",
                "/workspace/tools/comfyui",
                "/workspace/tools/wildcards",
                "/workspace/tools/prompts",
              ]}
              title="Tools"
              icon={<LibraryIcon width={24} height={24} />}
            >
              <MenuItem
                title="Recipes"
                icon={<ScienceIcon width={24} height={24} />}
                path="/workspace/tools/recipes"
                level={2}
                onSelected={onSelectedMenu}
              />
              <MenuItem
                title="ComfyUI"
                icon={<FlowSquareIcon width={24} height={24} />}
                path="/workspace/tools/comfyui"
                level={2}
                onSelected={onSelectedMenu}
              />
              <MenuItem
                title="Styles"
                icon={<ColorPickerIcon width={24} height={24} />}
                path="/workspace/tools/styles"
                level={2}
                onSelected={onSelectedMenu}
              />

              {!isProduction && (
                <MenuItem
                  title="Models"
                  icon={<ModelIcon className="w-6 h-6" />}
                  path="/workspace/tools/models"
                  level={2}
                />
              )}
              <MenuItem
                title="Wildcards"
                icon={<PinCodeIcon width={24} height={24} />}
                path="/workspace/tools/wildcards"
                level={2}
                onSelected={onSelectedMenu}
              />
              <MenuItem
                title="Prompts"
                icon={<BookMarkIcon2 width={24} height={24} />}
                path="/workspace/tools/prompts"
                level={2}
                onSelected={onSelectedMenu}
              />
            </MenuItemParent>
            <MenuItem
              onSelected={onSelectedMenu}
              title="Backlog"
              icon={<BackLogIcon width={24} height={24} />}
              path="/workspace/backlog"
            />
            <MenuItemParent
              title="Folders"
              values={["/workspace/folders", "/workspace/shared-with-me"]}
              icon={<FolderOpenIcon width={24} height={24} />}
            >
              <MenuItem
                onSelected={onSelectedMenu}
                title="My folders"
                icon={<MyFoldersIcon width={24} height={24} />}
                path="/workspace/folders"
                level={2}
              />
              <MenuItem
                onSelected={onSelectedMenu}
                title="Shared with me"
                icon={<UserMultiIcon width={24} height={24} />}
                path="/workspace/shared-with-me"
                level={2}
              />
            </MenuItemParent>
            <MenuItem
              onSelected={onSelectedMenu}
              title="Recent"
              icon={<ClockIcon width={24} height={24} />}
              path="/workspace/recent"
            />
            {/* <MenuItem
              onSelected={onSelectedMenu}
              title="Pinned"
              icon={<PinIcon width={24} height={24} />}
              path="/workspace/pinned"
            /> */}
            <MenuItem
              onSelected={onSelectedMenu}
              title="Trash"
              icon={<DeleteIcon width={24} height={24} />}
              path="/workspace/trash"
            />
          </div>
        </div>
      </div>
      <div className="">
        <ProgressbarStorage className="mb-4" />
        {/* <Link  
          className="block w-full px-4 py-2 text-xs font-semibold text-center rounded-lg bg-atherGray-850 hover:bg-atherGray-800"
          href={"#"}
        >
          Get more storage
        </Link> */}
        <div className="flex items-center justify-between p-2">
          <VersionApp />
          <div className="h-[1rem] w-[1px] bg-atherGray-800" />
          <ShortcutKeyboard />
        </div>
      </div>
    </div>
  )
}

export default SidebarBody
