import client from "@/api/client"
import { CompletedPart, FileAssociatedResource, UploadFile, UploadFileInfo } from "@/api/sdk"
import { UploadProgress } from "@/stores/useUploadProgressStore"
import { getBlurHash } from "@/utils/blurhash"
import { getMetadata } from "@/utils/parser"
import { mapSeries, mapWithConcurrency } from "@/utils/promise"
import axios, { AxiosProgressEvent } from "axios"
import { createMutation } from "react-query-kit"

type TFile = {
  id: string
}

export type InitializeMultipartUploadResult<T extends TFile> = {
  key: string
  filename: string
  uploadId: string
  uploadUrls: string[]
  file: T
}

export type CompleteMultipartUploadRequest = {
  key: string
  uploadId: string
  parts: CompletedPart[]
}

export type AbortMultipartUploadRequest = {
  key: string
  uploadId: string
  fileId: string
}

export type MultipartUpload<T extends TFile> = {
  files: File[]
  onProgress: (p: UploadProgress) => void
  onInitiateUpload: (infos: UploadFileInfo[]) => Promise<InitializeMultipartUploadResult<T>[]>
  onCompleteUpload: (request: CompleteMultipartUploadRequest) => Promise<void>
  onAbortUpload: (request: AbortMultipartUploadRequest) => Promise<void>
  chunkSize?: number
}

const MULTIPART_FILE_CHUNK = 1024 * 1024 * 10

const uploadPart = async (
  file: File,
  url: string,
  partNumber: number,
  chunkSize: number,
  onUploadProgress: (event: AxiosProgressEvent) => void,
) => {
  const part = file.slice((partNumber - 1) * chunkSize, partNumber * chunkSize)
  const result = await axios
    .put(url, part, {
      onUploadProgress,
    })
    .catch(e => {
      console.error(e)
      throw e
    })
  return {
    partNumber,
    eTag: result.headers["etag"],
  }
}

export const multipartUpload = async <T extends TFile = TFile>(options: MultipartUpload<T>) => {
  const {
    files,
    onProgress,
    onInitiateUpload: onInitiate,
    onCompleteUpload: onComplete,
    onAbortUpload: onAbort,
    chunkSize = MULTIPART_FILE_CHUNK,
  } = options

  if (!files.length) {
    return []
  }
  if (files.length > 50) {
    throw new Error("You can only upload up to 50 images at a time")
  }

  let progress = {
    current: 0,
    total: files.length,
    percent: 0,
    currentFile: 0,
    totalFile: files.length,
    enable: true,
  }
  onProgress(progress)

  const initializeMultipartFilesUploadInput: UploadFileInfo[] = await mapSeries(files, async (file, i) => {
    const info: UploadFileInfo = {
      filename: file.name,
      mimetype: file.type || "application/octet-stream", // default is binary
      fileSize: file.size,
      metadata: { width: 0, height: 0 }, // default for binary file
    }

    if (file.type.startsWith("image/")) {
      info.metadata = await getMetadata(file)
      // Blurhash generation is costly due to drawing the image on canvas, around 0.7s each
      info.blurhash = await getBlurHash(file)
    }

    progress.percent = Math.round(0.1 * (i / files.length) * 100)
    onProgress(progress)

    return info
  })

  const initializeMultipartUploadResult = await onInitiate(initializeMultipartFilesUploadInput)

  progress.percent = 20
  onProgress(progress)

  let partProgress = []
  await mapWithConcurrency(
    initializeMultipartUploadResult,
    async (multipart, i) => {
      try {
        const file = files.find(f => f.name === multipart.filename)
        if (!file) {
          throw new Error(`Failed to upload ${multipart.filename}`)
        }

        const generatePresignedUrls = multipart.uploadUrls

        const parts = await mapWithConcurrency(
          generatePresignedUrls,
          (url, index) =>
            uploadPart(file, url, index + 1, chunkSize, progressEvent => {
              if (!progressEvent.total) {
                return
              }
              // Save individual file's progress
              const percentOfPart = (progressEvent.loaded * 100) / progressEvent.total
              partProgress[`${file.name}-${index + 1}`] = percentOfPart
              // Sum progress
              let totalPartsPercent = Object.values(partProgress).reduce((prev, curr) => prev + curr, 0)
              // With the previous operations, we want to start the progress at 20%
              progress.percent =
                20 + Math.round(0.8 * (totalPartsPercent / generatePresignedUrls.length / progress.totalFile))
              onProgress({ ...progress, percent: progress.percent })
            }),
          { concurrency: 3 },
        )

        // Increment once finishing uploading all parts of the file
        progress.currentFile += 1
        onProgress({ ...progress, currentFile: progress.currentFile })
        await onComplete({
          key: multipart.key,
          uploadId: multipart.uploadId,
          parts,
        })
      } catch (err) {
        console.error(err)
        await onAbort({
          key: multipart.key,
          uploadId: multipart.uploadId,
          fileId: multipart.file.id,
        })
        onProgress({ enable: false, current: 0, percent: 0, currentFile: 0, total: 0, totalFile: 0 })
      }
    },
    { concurrency: 8 },
  )

  setTimeout(() => {
    progress = { current: 0, total: 0, percent: 0, currentFile: 0, totalFile: 0, enable: false }
    onProgress(progress)
  }, 1000)

  return initializeMultipartUploadResult.map(r => r.file)
}

// Define the new argument type
export type UploadFilesArgs = {
  files: File[]
  resourceType: FileAssociatedResource
  chunkSize?: number
  onProgress?: (p: UploadProgress) => void
  onError?: (e: Error) => void
}

export const uploadFiles = async ({ files, resourceType, chunkSize, onProgress, onError }: UploadFilesArgs) => {
  return multipartUpload<UploadFile>({
    files,
    chunkSize,
    onProgress: p => onProgress?.(p),
    async onInitiateUpload(infos) {
      return client.api
        .uploadControllerInitializeMultipartUpload({
          files: infos,
          associatedResource: resourceType,
          chunkSize,
        })
        .then(res => res.data)
    },
    async onCompleteUpload(request) {
      await client.api.uploadControllerCompleteMultipartUpload([request])
    },
    async onAbortUpload(request) {
      await client.api.uploadControllerAbortMultipartUpload([request]).finally(() => {
        onError?.(new Error("Upload aborted"))
      })
    },
  })
}

export const useUploadFileMutation = createMutation({
  mutationFn: async ({
    resourceType,
    files,
    chunkSize,
    onProgress,
    onError,
  }: {
    resourceType: FileAssociatedResource
    files: File[]
    chunkSize?: number
    onProgress?: (p: UploadProgress) => void
    onError?: (e: Error) => void
  }) => {
    return uploadFiles({ files, resourceType, chunkSize, onProgress, onError })
  },
})
